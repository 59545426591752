import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const authorid = localStorage.getItem("authorid");
    const token = localStorage.getItem("responsetoken");

    return authorid && token ? children : <Navigate to="/AdminLogin" />;
};

export default PrivateRoute;
