import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import  "../assets/css/dashboard.css";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [id, setId] = useState("");
    const [showOtpForm, setShowOtpForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post("https://api.rollerfashions.com/api/Authentication/Admin_Forgotpassword", { username: email });
            setLoading(false);
debugger;
            if (response.data.responseCode === 1) {
                toast.success("OTP sent to your email. Please check your inbox.");
                setId(response.data.responseobject); // Store the id from the response
                setShowOtpForm(true);
            } else {
                toast.error("Failed to send OTP. Please try again.");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error sending OTP:", error);
            toast.error("An error occurred. Please try again later.");
        }
    };
debugger;
    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
debugger;
        try {
            const response = await axios.post("https://api.rollerfashions.com/api/Authentication/Admin_otp", { username: email, otp: otp, id: id });
            setLoading(false);
            debugger;
            if (response.data.responseCode === 1) {
                toast.success("OTP verified successfully.");
                const token = response.data.responsetoken; // Assume token is returned in response
                localStorage.setItem('token', response.data.responsetoken); // Store the token from the response
                localStorage.setItem('id', response.data.responseid); // Store the id in localStorage
                navigate("/adminprofile"); // Navigate to reset password page
            } else {
                toast.error("Invalid OTP. Please try again.");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error verifying OTP:", error);
            toast.error("An error occurred. Please try again later.");
        }
    };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth">
                    <div className="row flex-grow">
                        <div className="col-lg-4 mx-auto">
                            <div className="text-left p-5">
                                <h4>Forgot Password</h4>
                                {!showOtpForm ? (
                                    <Form onSubmit={handleForgotPassword}>
                                        <Form.Group controlId="email">
                                            <Form.Label>Email *</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </Form.Group>
                                        <div className="text-center mt-5">
                                            <Button variant="primary" type="submit" className="btn-block enter-btn" disabled={loading}>
                                                {loading ? "Sending OTP..." : "Submit"}
                                            </Button>
                                        </div>
                                    </Form>
                                ) : (
                                    <Form onSubmit={handleVerifyOtp}>
                                        <Form.Group controlId="otp">
                                            <Form.Label>OTP *</Form.Label>
                                            <Form.Control type="text" placeholder="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
                                        </Form.Group>
                                        <div className="text-center mt-5">
                                            <Button variant="primary" type="submit" className="btn-block enter-btn" disabled={loading}>
                                                {loading ? "Verifying OTP..." : "Verify OTP"}
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                                <div className="text-center mt-3">
                                    <Link to="/adminlogin">Back to Login</Link>
                                </div>
                                <ToastContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;

