import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Sidenav from './LeftMenu';
import "react-toastify/dist/ReactToastify.css";



function AdminProfile() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [id, setId] = useState('');
    const [token, setToken] = useState(''); // Define token state
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedPassword = localStorage.getItem('password');
        const storedId = localStorage.getItem('id');
        const storedToken = localStorage.getItem('token');

        if (storedUsername) {
            setUsername(storedUsername);
        }
        if (storedPassword) {
            setPassword(storedPassword);
        }
        if (storedId) {
            setId(storedId);
        }
        if (storedToken) {
            setToken(storedToken);
        }
    }, []);
debugger;
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post("https://api.rollerfashions.com/api/Admin/Admin_Changepassword", {
                id: id,
                password: newPassword,
                // token: token, // Use the token state here
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`, 
                }
            });

            setLoading(false);

            if (response.data.responseCode === 1) {
                toast.success("Password changed successfully.");
                localStorage.setItem('password', newPassword);
                localStorage.removeItem('token'); // Clear the token from localStorage
                navigate("/adminlogin"); // Redirect to login page
            } else {
                toast.error("Failed to change password. Please try again.");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error changing password:", error);
            toast.error("An error occurred. Please try again later.");
        }
    };

    return (
        <Container fluid className="mt-5">
            <Row>
                <Col md={2} className="sidenav">
                    <Sidenav />
                </Col>
                <Col md={10}>
                    <div className="auth-form-light content-wrapper text-left p-5">
                        <h4>Admin Profile</h4>
          
                        <Form onSubmit={handleSubmit}>
                        <Container fluid>
                            <Row>
                                <Col md={12} xl={6} xxl={6}>
                                <Form.Group controlId="formUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    readOnly
                                />
                            </Form.Group>
                                </Col>
                                <Col md={12} xl={6} xxl={6}>
                                
                            <Form.Group controlId="formCurrentPassword">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter current password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    readOnly
                                />
                            </Form.Group>
                                </Col>
                                <Col md={12} xl={6} xxl={6}>
                                
                            <Form.Group controlId="formNewPassword">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter new password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </Form.Group>
                                </Col>
                                <Col md={12} xl={6} xxl={6}>
                                <Button variant="primary" type="submit" disabled={loading} className='mt-4'>
                                {loading ? "Changing Password..." : "Upadte Password"}
                            </Button>
                                </Col>
                            </Row>
                        </Container>
                        </Form>
                        <ToastContainer />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default AdminProfile;
