import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
function Addcoupon() {
    const [name, setName] = useState("");
    const [discount, setDiscount] = useState("");
    const [expiredon, setExpiredon] = useState("");
    const [activatedon, setActivatedon] = useState("");
    const [maxAmount, setMaxAmount] = useState("");
    const [minAmount, setMinAmount] = useState("");
    const [nooftimeuse, setNooftimeuse] = useState("");
    const [title, setTitle] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [editCoupon, setEditCoupon] = useState({});
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [deleteCouponId, setDeleteCouponId] = useState("");
    const [coupons, setCoupons] = useState([]);
    
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");

    useEffect(() => {
        fetchCoupons();
    }, []);

    const fetchCoupons = async () => {
        try {
            const response = await axios.get(`https://api.rollerfashions.com/api/Coupon/List_coupon?Token=${token}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setCoupons(response.data);
        } catch (error) {
            console.error("Error fetching coupons:", error);
            toast.error('Failed to fetch coupons');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // Convert activatedon and expiredon to Date objects
            const activatedDate = new Date(activatedon);
            const expiredDate = new Date(expiredon);
    
            // Check if the dates are valid before proceeding
            if (isNaN(activatedDate.getTime()) || isNaN(expiredDate.getTime())) {
                throw new Error("Invalid date format");
            }
    
            // Convert dates to ISO 8601 format
            const isoActivatedon = activatedDate.toISOString();
            const isoExpiredon = expiredDate.toISOString();
    
            const couponData = {
                name,
                discount: Number(discount),
                expiredon: isoExpiredon,
                activatedon: isoActivatedon,
                max_amount: Number(maxAmount),
                min_amount: Number(minAmount),
                nooftimeuse: Number(nooftimeuse),
                title,
                author_ID:authorid
            };
    
            const response = await axios.post('https://api.rollerfashions.com/api/Coupon/Add_coupon', couponData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
    
            console.log(response.data);
            toast.success('Coupon created successfully');
            setShowModal(false); // Close the modal after successful submission
    
            // Optionally, clear form fields after successful submission
            clearFormFields();
            fetchCoupons(); // Refresh coupon list after adding a new coupon
        } catch (error) {
            console.error("Error creating coupon:", error);
            toast.error('Failed to create coupon');
        }
    };

    const clearFormFields = () => {
        setName("");
        setDiscount("");
        setExpiredon("");
        setActivatedon("");
        setMaxAmount("");
        setMinAmount("");
        setNooftimeuse("");
        setTitle("");
    };

    const handleEditModalShow = async (couponId) => {
        try {
            const response = await axios.get(`https://api.rollerfashions.com/api/Coupon/Get_coupon?id=${couponId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            setEditCoupon(response.data);
            setEditModalShow(true);
        } catch (error) {
            console.error("Error fetching coupon details:", error);
            toast.error('Failed to fetch coupon details for editing');
        }
    };

    const handleEditModalClose = () => {
        setEditModalShow(false);
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();

        try {
            // Convert activatedon and expiredon to Date objects
            const activatedDate = new Date(editCoupon.activatedon);
            const expiredDate = new Date(editCoupon.expiredon);
    
            // Check if the dates are valid before proceeding
            if (isNaN(activatedDate.getTime()) || isNaN(expiredDate.getTime())) {
                throw new Error("Invalid date format");
            }
    
            // Convert dates to ISO 8601 format
            const isoActivatedon = activatedDate.toISOString();
            const isoExpiredon = expiredDate.toISOString();

            const editedCouponData = {
                id: editCoupon.id,
                name: editCoupon.name,
                discount: Number(editCoupon.discount),
                expiredon: isoExpiredon,
                activatedon: isoActivatedon,
                max_amount: Number(editCoupon.max_amount),
                min_amount: Number(editCoupon.min_amount),
                nooftimeuse: Number(editCoupon.nooftimeuse),
                title: editCoupon.title,
                author_ID:authorid
            };

            const response = await axios.post(`https://api.rollerfashions.com/api/Coupon/Edit_coupon?id=${editCoupon.id}`, editedCouponData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            console.log(response.data);
            toast.success('Coupon updated successfully');
            handleEditModalClose(); // Close the edit modal after successful submission
            fetchCoupons(); // Refresh coupon list after editing
        } catch (error) {
            console.error("Error updating coupon:", error);
            toast.error('Failed to update coupon');
        }
    };

    const handleDeleteModalShow = (couponId) => {
        setDeleteCouponId(couponId);
        setDeleteModalShow(true);
    };

    const handleDeleteModalClose = () => {
        setDeleteModalShow(false);
    };

    const handleDeleteCoupon = async () => {
        try {
            const deleteData = {
                id: deleteCouponId,
                token
            };
    
            const response = await axios.post(
                'https://api.rollerfashions.com/api/Coupon/Delete_coupon',
                deleteData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            console.log(response.data);
            toast.success('Coupon deleted successfully');
            handleDeleteModalClose();
            fetchCoupons();
        } catch (error) {
            console.error("Error deleting coupon:", error);
            toast.error('Failed to delete coupon');
        }
    };
    
    
    

    return (
        <div className="container-fluid addcoupon addcoupon-container">
            <h2 className='coupon-title'>Coupon Management / Add Coupon</h2>
            <Button variant="primary" onClick={() => setShowModal(true)}>Add Coupon</Button>
             
             <div className='content-wrapper'>


             <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} required />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="discount">
                                    <Form.Label>Discount</Form.Label>
                                    <Form.Control type="number" placeholder="Enter discount" value={discount} onChange={(e) => setDiscount(e.target.value)} required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="activatedon">
                                    <Form.Label>Activated On</Form.Label>
                                    <Form.Control type="datetime-local" placeholder="Enter activation date" value={activatedon} onChange={(e) => setActivatedon(e.target.value)} required />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="expiredon">
                                    <Form.Label>Expired On</Form.Label>
                                    <Form.Control type="datetime-local" placeholder="Enter expiration date" value={expiredon} onChange={(e) => setExpiredon(e.target.value)} required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="maxAmount">
                                    <Form.Label>Max Amount</Form.Label>
                                    <Form.Control type="number" placeholder="Enter max amount" value={maxAmount} onChange={(e) => setMaxAmount(e.target.value)} required />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="minAmount">
                                    <Form.Label>Min Amount</Form.Label>
                                    <Form.Control type="number" placeholder="Enter min amount" value={minAmount} onChange={(e) => setMinAmount(e.target.value)} required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="nooftimeuse">
                                    <Form.Label>Number of Times Use</Form.Label>
                                    <Form.Control type="number" placeholder="Enter number of times use" value={nooftimeuse} onChange={(e) => setNooftimeuse(e.target.value)} required />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button variant="primary" type="submit">Add Coupon</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Edit Modal */}
            <Modal show={editModalShow} onHide={handleEditModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditSubmit}>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="editName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter name" value={editCoupon.name} onChange={(e) => setEditCoupon({ ...editCoupon, name: e.target.value })} required />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="editDiscount">
                                    <Form.Label>Discount</Form.Label>
                                    <Form.Control type="number" placeholder="Enter discount" value={editCoupon.discount} onChange={(e) => setEditCoupon({ ...editCoupon, discount: e.target.value })} required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="editActivatedon">
                                    <Form.Label>Activated On</Form.Label>
                                    <Form.Control type="datetime-local" placeholder="Enter activation date" value={editCoupon.activatedon ? editCoupon.activatedon.slice(0, -1) : ''} onChange={(e) => setEditCoupon({ ...editCoupon, activatedon: e.target.value })} required />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="editExpiredon">
                                    <Form.Label>Expired On</Form.Label>
                                    <Form.Control type="datetime-local" placeholder="Enter expiration date" value={editCoupon.expiredon ? editCoupon.expiredon.slice(0, -1) : ''} onChange={(e) => setEditCoupon({ ...editCoupon, expiredon: e.target.value })} required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="editMaxAmount">
                                    <Form.Label>Max Amount</Form.Label>
                                    <Form.Control type="number" placeholder="Enter max amount" value={editCoupon.max_amount} onChange={(e) => setEditCoupon({ ...editCoupon, max_amount: e.target.value })} required />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="editMinAmount">
                                    <Form.Label>Min Amount</Form.Label>
                                    <Form.Control type="number" placeholder="Enter min amount" value={editCoupon.min_amount} onChange={(e) => setEditCoupon({ ...editCoupon, min_amount: e.target.value })} required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="editNooftimeuse">
                                    <Form.Label>Number of Times Use</Form.Label>
                                    <Form.Control type="number" placeholder="Enter number of times use" value={editCoupon.nooftimeuse} onChange={(e) => setEditCoupon({ ...editCoupon, nooftimeuse: e.target.value })} required />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="editTitle">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" placeholder="Enter title" value={editCoupon.title} onChange={(e) => setEditCoupon({ ...editCoupon, title: e.target.value })} required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button variant="primary" type="submit">Update Coupon</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Delete Modal */}
            <Modal show={deleteModalShow} onHide={handleDeleteModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this coupon?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteModalClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteCoupon}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Table className='table-dark'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Discount</th>
                        <th>Activated On</th>
                        <th>Expired On</th>
                        <th>Max Amount</th>
                        <th>Min Amount</th>
                        <th>Number of Times Use</th>
                        <th>Title</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {coupons.map((coupon) => (
                        <tr key={coupon.id}>
                            <td>{coupon.name}</td>
                            <td>{coupon.discount}</td>
                            <td>{new Date(coupon.activatedon).toLocaleString()}</td>
                            <td>{new Date(coupon.expiredon).toLocaleString()}</td>
                            <td>{coupon.max_amount}</td>
                            <td>{coupon.min_amount}</td>
                            <td>{coupon.nooftimeuse}</td>
                            <td>{coupon.title}</td>
                            <td>
                                <Button variant="info" onClick={() => handleEditModalShow(coupon.id)}><FaEdit /></Button>
                                <Button variant="danger" onClick={() => handleDeleteModalShow(coupon.id)}><FaTrash /></Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
             </div>


            <ToastContainer />
        </div>
    );
}

export default Addcoupon;
