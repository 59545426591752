// import React, { useState } from 'react';
// import { Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import CSVReader from 'react-csv-reader';

// const CSVUpload = ({ onUploadSuccess }) => {
//     const [csvData, setCsvData] = useState([]);
//     const token = localStorage.getItem('responsetoken');
//     const authorid = localStorage.getItem("authorid");
//     const handleOnFileLoaded = (data) => {
//         setCsvData(data);
//     };

//     const handleUpload = async () => {
//         if (csvData.length === 0) {
//             toast.error('No CSV data to upload');
//             return;
//         }
// debugger;
//         try {
//             for (let i = 1; i < csvData.length; i++) { // Skip header row
//                 const row = csvData[i];
//                 const formData = new FormData();

//                 formData.append('Name', row[0]);
//                 formData.append('Color_Name', row[1]);
//                 formData.append('Category_Name', row[2]);
//                 formData.append('Subcategory_Name', row[3]);
//                 formData.append('Label_Name', row[4]);
//                 formData.append('XS', row[5]);
//                 formData.append('S', row[6]);
//                 formData.append('M', row[7]);
//                 formData.append('L', row[8]);
//                 formData.append('XL', row[9]);
//                 formData.append('XXL', row[10]);
//                 formData.append('Stock', row[11]);
//                 formData.append('Price', row[12]);
//                 formData.append('Selling_price', row[13]);
//                 formData.append('Discount', row[14]);
//                 formData.append('Tax', row[15]);
//                 formData.append('Isfeature', row[16]);
//                 formData.append('Isavailable', row[17]);
//                 formData.append('Description', row[18]);
//                 formData.append('Display_image', row[19]); // Assuming these are URLs or base64 encoded strings
//                 formData.append('Sample_image1', row[20]);
//                 formData.append('Sample_image2', row[21]);
//                 formData.append('Sample_image3', row[22]);
//                 formData.append('Sample_image4', row[23]);
//                 formData.append('PackageWeight', row[24]);
//                 formData.append('Packageheight', row[25]);
//                 formData.append('Packagelength', row[26]);
//                 formData.append('Packagewidth', row[27]);
//                 formData.append('Fabrictype', row[28]);
//                 formData.append('Manufaturedby', row[29]);
//                 formData.append('Washcare', row[30]);
//                 formData.append('fit', row[31]);
//                 formData.append('Gender', row[32]);
//                 formData.append('Skq_number', row[33]);

//                 await axios.post('https://api.rollerfashions.com/api/Product/Bulk_ProductUpload', formData, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data',
//                         'Authorization': `Bearer ${token}`,
//                     },
//                 });
//             }

//             toast.success('Products created successfully!');
//             if (onUploadSuccess) onUploadSuccess();
//         } catch (error) {
//             console.error('Error uploading CSV data:', error);
//             toast.error('Error uploading CSV data');
//         }
//     };

//     return (
//         <div className="csv-upload mt-3">
//             <CSVReader
//                 onFileLoaded={handleOnFileLoaded}
//                 onError={(err) => console.error('CSV reading error:', err)}
//                 parserOptions={{ header: false }} // Adjust parser options as needed
//             />
//             <Button onClick={handleUpload} variant="primary" className="mt-3">
//                 Upload CSV
//             </Button>
//         </div>
//     );
// };

// export default CSVUpload;
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CSVReader from 'react-csv-reader';

const CSVUpload = forwardRef(({ onUploadSuccess }, ref) => {
    const [csvData, setCsvData] = useState([]);
    const token = localStorage.getItem('responsetoken');
    const authorid = localStorage.getItem("authorid");

    const handleOnFileLoaded = (data) => {
        setCsvData(data);
    };

    const handleUpload = async () => {
        if (csvData.length === 0) {
            toast.error('No CSV data to upload');
            return;
        }

        try {
            for (let i = 1; i < csvData.length; i++) { // Skip header row
                const row = csvData[i];
                const formData = new FormData();

                formData.append('Name', row[0]);
                formData.append('Color_Name', row[1]);
                formData.append('Category_Name', row[2]);
                formData.append('Subcategory_Name', row[3]);
                formData.append('Label_Name', row[4]);
                formData.append('XS', row[5]);
                formData.append('S', row[6]);
                formData.append('M', row[7]);
                formData.append('L', row[8]);
                formData.append('XL', row[9]);
                formData.append('XXL', row[10]);
                formData.append('Stock', row[11]);
                formData.append('Price', row[12]);
                formData.append('Selling_price', row[13]);
                formData.append('Discount', row[14]);
                formData.append('Tax', row[15]);
                formData.append('Isfeature', row[16]);
                formData.append('Isavailable', row[17]);
                formData.append('Description', row[18]);
                formData.append('Display_image', row[19]); // Assuming these are URLs or base64 encoded strings
                formData.append('Sample_image1', row[20]);
                formData.append('Sample_image2', row[21]);
                formData.append('Sample_image3', row[22]);
                formData.append('Sample_image4', row[23]);
                formData.append('PackageWeight', row[24]);
                formData.append('Packageheight', row[25]);
                formData.append('Packagelength', row[26]);
                formData.append('Packagewidth', row[27]);
                formData.append('Fabrictype', row[28]);
                formData.append('Manufaturedby', row[29]);
                formData.append('Washcare', row[30]);
                formData.append('fit', row[31]);
                formData.append('Gender', row[32]);
                formData.append('Skq_number', row[33]);

                await axios.post('https://api.rollerfashions.com/api/Product/Bulk_ProductUpload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                });
            }

            toast.success('Products created successfully!');
            if (onUploadSuccess) onUploadSuccess();
            reset(); // Call reset after successful upload
        } catch (error) {
            console.error('Error uploading CSV data:', error);
            toast.error('Error uploading CSV data');
        }
    };

    // Reset function to clear CSV data
    const reset = () => {
        setCsvData([]);
        // Reset CSVReader (if needed)
        // Note: CSVReader might not support direct reset, but you can work around by clearing input
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.value = null; // Clear the file input
        }
    };

    // Expose reset method to parent
    useImperativeHandle(ref, () => ({
        reset,
    }));

    return (
        <div className="csv-upload mt-3">
            <CSVReader
                onFileLoaded={handleOnFileLoaded}
                onError={(err) => console.error('CSV reading error:', err)}
                parserOptions={{ header: false }} // Adjust parser options as needed
            />
            <Button onClick={handleUpload} variant="primary" className="mt-3">
                Upload CSV
            </Button>
        </div>
    );
});

export default CSVUpload;
