import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast, ToastContainer } from "react-toastify";
import { Link } from 'react-router-dom';
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/dashboard.css";



function Admin_Login({ handleClose }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async (e) => {
        e.preventDefault();

        const url = "https://api.rollerfashions.com/api/Authentication/Admin_login";
        const data = {
            username: username,
            password: password,
           
        };

        try {
            const response = await axios.post(url, data, {
                headers: {
                    "Content-Type": "application/json",
                    
                },
            });

            const responseData = response.data;

            if (responseData.responseCode === 1) {
                toast.success(responseData.responsemessage, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                localStorage.setItem("responsetoken", responseData.responsetoken);
                localStorage.setItem("username", responseData.username); 
                localStorage.setItem("authorid", responseData.responseobject);// Save the email

                window.location.href = "/AdminDashboard/dashboardcounts";
            } else {
                toast.error("Login failed. Please check your credentials.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            console.error("Axios error:", error);
            toast.error("An error occurred. Please try again.", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    return (
        <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper full-page-wrapper d-flex align-items-center auth login-bg">
                <div className="card col-lg-4 mx-auto">
                    <div className="card-body px-5 py-5">
                        <h3 className="card-title text-left mb-3 custom_logintitle">Login</h3>
                        <Form onSubmit={handleLogin}>
                            <Form.Group controlId="email">
                                <Form.Label>Username or email *</Form.Label>
                                <Form.Control type="text" placeholder="Enter email" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label>Password *</Form.Label>
                                <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </Form.Group>
                            <div className="text-center mt-5">
                                <Button variant="primary" type="submit" className="btn-block enter-btn">Login</Button>
                            </div>
                        </Form>
                        <div className="text-center mt-3">
                            <Link to="/forgotpassword">Forgot Password</Link> 
                        </div>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Admin_Login;


