import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Container, Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';

const CustomBannerList = () => {
  const [banners, setBanners] = useState([]);
  const token = localStorage.getItem("responsetoken");
  const authorId = localStorage.getItem("authorid");

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    try {
      const response = await axios.get('https://api.rollerfashions.com/api/Banner/List_custombanner', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setBanners(response.data);
    } catch (error) {
      console.error("Error fetching banners:", error);
      toast.error('Error fetching banners');
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this banner?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post('https://api.rollerfashions.com/api/Banner/Delete_custombanner', 
            {
              id: id,
              author_ID: authorId
            },
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              }
            }
          );
          toast.success('Banner deleted successfully!');
          fetchBanners(); // Refresh the list after deletion
        } catch (error) {
          console.error('Error deleting banner:', error);
          toast.error('Error deleting banner');
        }
      }
    });
  };

  return (
    <Container fluid className="container-fluid content-wrapper ">
      <h2 className="coupon-title  mb-4">Custom Banner List</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Image</th>
            <th>Description</th>
            <th>URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {banners.map((banner) => (
            <tr key={banner.id}>
              <td><img src={banner.image} alt="Banner" style={{ width: '100px' }} /></td>
              <td>{banner.description}</td>
              <td><a href={banner.url} target="_blank" rel="noopener noreferrer">{banner.url}</a></td>
              <td>
                <Button variant="danger" onClick={() => handleDelete(banner.id)}><FaTrash /></Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ToastContainer />
    </Container>
  );
};

export default CustomBannerList;
