import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import Sidenav from './LeftMenu';
import "../assets/css/editcombo.css"
function EditCombo() {
    const [itemName, setItemName] = useState("");
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [dimensions, setDimensions] = useState("");
    const [price, setPrice] = useState("");
    const [discount, setDiscount] = useState("");
    const [stock, setStockQty] = useState("");
    const [isFeatured, setIsFeatured] = useState(false);
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [sampleImages, setSampleImages] = useState(new Array(4).fill(null));
    const [tax, setTax] = useState("");
    const [packageWeight, setPackageWeight] = useState("");
    const [packageHeight, setPackageHeight] = useState("");
    const [packageLength, setPackageLength] = useState("");
    const [packageWidth, setPackageWidth] = useState("");
    const [gender, setGender] = useState("");
    const [skqNumber, setSkqNumber] = useState("");
    const [noOfPieces, setNoOfPieces] = useState("");
    const [isAvailable, setIsAvailable] = useState(false);
    const [videoFile, setVideoFile] = useState(null);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const token = localStorage.getItem("responsetoken");
    const authorid = localStorage.getItem("authorid");
    const { id } = useParams();

    useEffect(() => {
        fetchComboDetails();
        fetchCategories();
    }, []);
debugger;
    const fetchComboDetails = async () => {
        try {
            const url = `https://api.rollerfashions.com/api/Combo_product/Get_comboproduct?ID=${id}`;
            const response = await axios.get(url,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            const comboData = response.data;
            setItemName(comboData.name);
            setCategory(comboData.category_ID);
            setSubCategory(comboData.subcategory_ID);
            setDimensions(comboData.dimensions);
            setPrice(comboData.price);
            setDiscount(comboData.discount);
            setStockQty(comboData.stock);
            setIsFeatured(comboData.isfeature);
            setDescription(comboData.description);
            setTax(comboData.tax);
            setPackageWeight(comboData.packageWeight);
            setPackageHeight(comboData.packageheight);
            setPackageLength(comboData.packagelength);
            setPackageWidth(comboData.packagewidth);
            setGender(comboData.gender);
            setSkqNumber(comboData.skq_number);
            setNoOfPieces(comboData.noof_pieces);
            setIsAvailable(comboData.isavailable);
        } catch (error) {
            console.error("Error fetching combo product details:", error);
        }
    };

    const fetchCategories = async () => {
        try {
            const url = `https://api.rollerfashions.com/api/Category/List_Category`;
 
            
            const response = await axios.get(url, {            
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const fetchSubCategories = async (categoryID) => {
        try {
            const url = `https://api.rollerfashions.com/api/SubCategory/List_SubCategory?Category_ID=${categoryID}`;
            const response = await axios.get(url,{
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            });
            setSubCategories(response.data);
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result.split(',')[1]); // Remove the "data:image/png;base64," prefix
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const imageBase64 = image ? await convertToBase64(image) : "";
        const sampleImagesBase64 = await Promise.all(sampleImages.map(img => img ? convertToBase64(img) : ""));

        const data = {
            id: id,
            name: itemName,
            category_ID: category,
            subcategory_ID: subCategory,
            dimensions: dimensions,
            price: price,
            discount: discount,
            stock: stock,
            isfeature: isFeatured,
            description: description,
            display_image: imageBase64,
            sample_image1: sampleImagesBase64[0],
            sample_image2: sampleImagesBase64[1],
            sample_image3: sampleImagesBase64[2],
            sample_image4: sampleImagesBase64[3],
            tax: tax,
            packageWeight: packageWeight,
            packageheight: packageHeight,
            packagelength: packageLength,
            packagewidth: packageWidth,
            gender: gender,
            skq_number: skqNumber,
            noof_pieces: noOfPieces,
            isavailable: isAvailable,
            author_ID:authorid,
        };

        try {
            const url = 'https://api.rollerfashions.com/api/Combo_product/Edit_comboproduct';
            const response = await axios.post(url, data,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            console.log(response.data);
            toast.success('Combo product updated successfully');
        } catch (error) {
            console.error("Error editing combo product:", error);
            toast.error('Failed to update combo product');
        }
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size <= 102400) { // Check if file size is <= 100KB (102400 bytes)
                setImage(file);
            } else {
                toast.error("Image size should be less than or equal to 100KB");
            }
        }
    };

    const handleSampleImageUpload = async (e, index) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size <= 102400) { // Check if file size is <= 100KB (102400 bytes)
                const updatedSampleImages = [...sampleImages];
                updatedSampleImages[index] = file;
                setSampleImages(updatedSampleImages);
            } else {
                toast.error("Image size should be less than or equal to 100KB");
            }
        }
    };

    const handleVideoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVideoFile(file);
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col md={2}>
                    <Sidenav />
                </Col>
                <Col md={10}>
                    <div className="container-fluid editcombo content-wrapper">
                        <div className="card editcombo-card">
                            <div className="coupon-title">
                                <h2>Edit Combo Product</h2>
                                <Form onSubmit={handleSubmit}>
                                    {/* Form fields with initial values from state */}
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="itemName">
                                                <Form.Label>Item Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter item name" value={itemName} onChange={(e) => setItemName(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="category">
                                                <Form.Label>Category</Form.Label>
                                                <Form.Control as="select" value={category} onChange={(e) => {
                                                    setCategory(e.target.value);
                                                    fetchSubCategories(e.target.value);
                                                }}>
                                                    <option value="">Select Category</option>
                                                    {categories.map(cat => (
                                                        <option key={cat.id} value={cat.id}>{cat.name}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="subCategory">
                                                <Form.Label>Subcategory</Form.Label>
                                                <Form.Control as="select" value={subCategory} onChange={(e) => setSubCategory(e.target.value)}>
                                                    <option value="">Select Subcategory</option>
                                                    {subCategories.map(subCat => (
                                                        <option key={subCat.id} value={subCat.id}>{subCat.name}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="dimensions">
                                                <Form.Label>Dimensions</Form.Label>
                                                <Form.Control type="text" placeholder="Enter dimensions" value={dimensions} onChange={(e) => setDimensions(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="price">
                                                <Form.Label>Price</Form.Label>
                                                <Form.Control type="text" placeholder="Enter price" value={price} onChange={(e) => setPrice(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="discount">
                                                <Form.Label>Discount</Form.Label>
                                                <Form.Control type="text" placeholder="Enter discount" value={discount} onChange={(e) => setDiscount(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="stockQty">
                                                <Form.Label>Stock Quantity</Form.Label>
                                                <Form.Control type="text" placeholder="Enter stock quantity" value={stock} onChange={(e) => setStockQty(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="isFeatured">
                                                <Form.Check type="checkbox" label="Is Featured" checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="description">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="tax">
                                                <Form.Label>Tax</Form.Label>
                                                <Form.Control type="text" placeholder="Enter tax" value={tax} onChange={(e) => setTax(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="packageWeight">
                                                <Form.Label>Package Weight</Form.Label>
                                                <Form.Control type="text" placeholder="Enter package weight" value={packageWeight} onChange={(e) => setPackageWeight(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="packageHeight">
                                                <Form.Label>Package Height</Form.Label>
                                                <Form.Control type="text" placeholder="Enter package height" value={packageHeight} onChange={(e) => setPackageHeight(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="packageLength">
                                                <Form.Label>Package Length</Form.Label>
                                                <Form.Control type="text" placeholder="Enter package length" value={packageLength} onChange={(e) => setPackageLength(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="packageWidth">
                                                <Form.Label>Package Width</Form.Label>
                                                <Form.Control type="text" placeholder="Enter package width" value={packageWidth} onChange={(e) => setPackageWidth(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="gender">
                                                <Form.Label>Gender</Form.Label>
                                                <Form.Control type="text" placeholder="Enter gender" value={gender} onChange={(e) => setGender(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="skqNumber">
                                                <Form.Label>SKQ Number</Form.Label>
                                                <Form.Control type="text" placeholder="Enter SKQ number" value={skqNumber} onChange={(e) => setSkqNumber(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="noOfPieces">
                                                <Form.Label>No. of Pieces</Form.Label>
                                                <Form.Control type="text" placeholder="Enter number of pieces" value={noOfPieces} onChange={(e) => setNoOfPieces(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="isAvailable">
                                                <Form.Check type="checkbox" label="Is Available" checked={isAvailable} onChange={(e) => setIsAvailable(e.target.checked)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="videoFile">
                                                <Form.Label>Video File</Form.Label>
                                                <Form.Control type="file" accept="video/*" onChange={handleVideoUpload} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="image">
                                                <Form.Label>Display Image</Form.Label>
                                                <Form.Control type="file" accept="image/*" onChange={handleImageUpload} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="sampleImages">
                                                <Form.Label>Sample Images</Form.Label>
                                                {sampleImages.map((_, index) => (
                                                    <Form.Control key={index} type="file" accept="image/*" onChange={(e) => handleSampleImageUpload(e, index)} className="mb-2" />
                                                ))}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button variant="primary" type="submit">
                                        Update Combo Product
                                    </Button>
                                </Form>
                            </div>
                        </div>
                        <ToastContainer />
                    </div>
                </Col>
            </Row>


        </Container>
    );
}

export default EditCombo;
